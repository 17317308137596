// Composables
import { createRouter, createWebHistory } from "vue-router";
import Pronounce from "@/views/pron/Pronounce.vue";
import Introduction from "@/views/Introduction.vue";
import SelectLanguage from "@/views/SelectLanguage.vue";
import Terms from "@/views/Terms.vue";
import { AUTH_TOKEN } from "@/plugins/apollo";
const routes = [
  {
    path: "/pron",
    component: Pronounce,
    name: "pronounce",
    meta: {requiresAuth: true}
  },
  {
    path: "/aboutus",
    component: () => import("@/views/AboutUs.vue"),
    name: "AboutUs",
    meta: {requiresAuth: false}
  },
  {
    path: "/subscription",
    component: () => import("@/views/Subscription.vue"),
    name: "subscription",
    meta: {requiresAuth: false}
  },
  {
    path: '/introduction',
    component: Introduction,
    name: "introduction",
    meta: {requiresAuth: true}

  },
  { 
    path: '',
    component: SelectLanguage,
    name: "selectlang",
    meta: {requiresAuth: false}

  },
  { 
    path: '/terms',
    component: Terms,
    name: "terms",
    meta: {requiresAuth: false}

  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem(AUTH_TOKEN) != undefined && localStorage.getItem(AUTH_TOKEN) != 'undefined'
  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      next({ name: "selectlang" });
    } else if (
      to.meta.userTypeAllowed &&
      !to.meta.userTypeAllowed.includes(userType)
    ) {
      next({ name: "selectlang" }); // We can add error component , in router
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
