<template>
  <v-card max-width="600" flat class="rounded-b-xl" theme="dark" color="transparent" width="100%" tile>
    <v-bottom-sheet inset fullscreen v-model="showDialog" class="d-flex">
      <v-card>
        <v-card-actions>
          <v-btn color="error" @click="showDialog = false" :icon="mdiClose"></v-btn>
        </v-card-actions>
        <div class="d-flex justify-space-around">
          <v-card flat max-height="90vh" class="overflow-scroll fill-height">
            <v-card-title>
              {{ $t('chapter') }}
            </v-card-title>
            <v-card-text style="max-height: 70vh">
              <v-tabs grow :prev-icon="mdiChevronDown" :next-icon="mdiChevronUp" center-active show-arrows
                direction="vertical" :model-value="sora" @update:model-value="updateSora">
                <v-tab v-for="(s, index) in sorat" :value="s">
                  {{ s[$i18n.locale] }}
                </v-tab>
              </v-tabs>
            </v-card-text>
          </v-card>
          <v-card flat max-height="90vh" class="overflow-scroll fill-height">
            <v-card-title>
              {{ $t('verse') }}
            </v-card-title>
            <v-card-text style="max-height: 70vh">
              <v-tabs grow :prev-icon="mdiChevronDown" :next-icon="mdiChevronUp" center-active show-arrows
                direction="vertical" :model-value="ayaNumber" @update:model-value="updateAyaNumber">
                <v-tab v-for="a in ayat" :value="a">
                  {{ a }}
                </v-tab>
              </v-tabs>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-card-actions v-if="sorat && sora" class="justify-space-between">
      <v-btn @click="prevSora" :icon="prevprev" :disabled="sora.id == sorat[0]?.id"></v-btn>

      <v-btn variant="text" @click="showDialog = true" class="text-caption">
        {{ sora[$i18n.locale] }} {{ ayaNumber }}
      </v-btn>
      <v-btn @click="nextSora" :icon="nextnext" :disabled="sora.id == sorat[sorat.length - 1]?.id"></v-btn>
    </v-card-actions>
    <v-card-actions v-if="sora" class="justify-space-between">
      <v-btn help="audto play the meaning" :disabled="ayaNumber <= 1"
        @click="$emit('update:ayaNumber', this.ayaNumber - 1)" rounded :icon="prev"></v-btn>
      <v-btn :icon="mdiMenu" @click="showMenu = !showMenu"></v-btn>
      <v-btn :icon="mdiVolumeHigh" @click="$emit('recite')"></v-btn>
      <MeaningCard :sora="sora" :aya="aya"></MeaningCard>
      <v-btn :disabled="ayaNumber == sora.ayatCount" @click=" $emit('update:ayaNumber', this.ayaNumber + 1)" rounded
        :icon="next">
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { useAppStore } from "@/store/app"
import { SORAT } from "@/gql/quran/queries"
import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiClose, mdiMenu, mdiVolumeHigh } from "@mdi/js";
import { mapWritableState } from "pinia";
import MeaningCard from '@/components/MeaningCard.vue';
export default {
  name: "SoraInput",
  emits: ["update:sora", "update:ayaNumber"],
  props: {
    sora: Object,
    ayaNumber: Number,
    aya: Object
  },
  data() {
    return {
      sorat: [],
      showDialog: false
    };
  },
  created() {
    this.mdiMenu = mdiMenu
    this.showMenu = false
    this.mdiVolumeHigh = mdiVolumeHigh
    this.mdiClose = mdiClose
    this.mdiChevronDown = (this.$i18n.locale == 'ar') ? mdiChevronDown : mdiChevronUp
    this.mdiChevronUp = (this.$i18n.locale == 'ar') ? mdiChevronUp : mdiChevronDown
    this.next = (this.$i18n.locale == 'ar') ? mdiChevronLeft : mdiChevronRight
    this.prev = (this.$i18n.locale == 'ar') ? mdiChevronRight : mdiChevronLeft
    this.nextnext = (this.$i18n.locale == 'ar') ? mdiChevronDoubleLeft : mdiChevronDoubleRight
    this.prevprev = (this.$i18n.locale == 'ar') ? mdiChevronDoubleRight : mdiChevronDoubleLeft
  },
  apollo: {
    sorat: {
      query: SORAT,
      result({ data }) {
        let sora
        if (localStorage.getItem('sora')) {
          sora = data.sorat.find((s) => s.id == localStorage.getItem('sora'))
        } else {
          sora = data.sorat[0];
        }
        this.$emit('update:sora', sora)
        this.$emit('update:ayaNumber', 1)
      }
    },
  },
  methods: {
    nextSora() {
      const soraIndex = this.sorat.findIndex((s) => s.id == this.sora.id)
      if (soraIndex < this.sorat.length - 1) {
        this.updateSora(this.sorat[soraIndex + 1])
      }
    },
    prevSora() {
      const soraIndex = this.sorat.findIndex((s) => s.id == this.sora.id)
      if (soraIndex > 0) {
        this.updateSora(this.sorat[soraIndex - 1])
      }
    },
    updateSora(val) {
      localStorage.setItem('sora', this.sora.id)
      this.$emit("update:sora", val);
      this.$emit("update:ayaNumber", 1);
    },
    updateAyaNumber(val) {
      this.$emit("update:ayaNumber", val);
    }
  },
  computed: {
    ...mapWritableState(useAppStore, ['showMenu']),
    ayat() {
      if (this.sora)
        return Array.from(Array(this.sora.ayatCount).keys(), (_, k) => k + 1)
    }
  },
};
</script>
