<template>
    <v-card flat style="overflow-y: auto;" max-height="85dvh">
        <v-card-title>
            {{ $t('Terms and Conditions') }}
        </v-card-title>
        <v-card-text :class="($i18n.locale == 'ar') ? 'text-right' : 'text-left'">
            <div v-html="terms"></div>
        </v-card-text>
        <v-radio-group v-model="canPay">
            <v-radio :value="0" :label="$t('Let me use the app for free')"></v-radio>
            <v-radio :value="10" :label="$t('I can afford 10$/mo subscription')"></v-radio>
        </v-radio-group>
        <v-card-actions>
            <v-btn :disabled="canPay == undefined" :loading="loading" @click="accept" color="primary" block
                variant="elevated">
                {{ $t('Agree') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { TOKEN } from '@/gql/user/mutations'
import { AUTH_TOKEN } from '@/plugins/apollo'

export default {
    name: "Terms",
    data() {
        return {
            terms: null,
            loading: false,
            canPay: null
        }
    },
    mounted() {
        fetch(`${this.$i18n.locale}Terms.html`)
            .then(response => response.text())
            .then(text => { this.terms = text })
    },
    methods: {
        accept() {
            this.loading = true
            this.$apollo.mutate({
                mutation: TOKEN,
                variables: {
                    canPay: this.canPay
                },
            }).then(({ data }) => {
                this.loading = false
                localStorage.setItem(AUTH_TOKEN, data.token)
                this.$router.replace({ name: 'introduction' })
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>