<template>
  <v-banner style="z-index: 2001" lines="one" sticky color="primary" class="d-flex flex-row"
    v-if="isInstallButtonVisible">
    <v-banner-text style="font-size: small" class="ml-5">{{
      $t("Install ILearnQuran app on your device?")
    }}</v-banner-text>
    <template v-slot:actions>
      <v-btn id="installButton" @click="installApp" color="white" size="small"
        style="background-color: rgb(0, 153, 255); border-radius: 15px">{{ $t("yes") }}</v-btn>
      &nbsp;
      <v-btn id="installButton" @click="isInstallButtonVisible = false" color="white" size="small"
        style="background-color: rgb(0, 153, 255); border-radius: 15px">{{ $t("no") }}</v-btn>
    </template>
  </v-banner>
</template>
<script>
import { mdiCellphoneArrowDown } from "@mdi/js";
let deferredPrompt;
export default {
  name: "PwaInstallButton",
  data() {
    return {
      isAppInstalled: false,
      mdiCellphoneArrowDown,
      isInstallButtonVisible: false,
    };
  },
  created() {
    window.addEventListener("appinstalled", () => {
      this.isInstallButtonVisible = false;
    });
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      this.isInstallButtonVisible = true;
    });
  },
  methods: {
    installApp() {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the PWA installation");
          } else {
            console.log("User declined the PWA installation");
          }
          deferredPrompt = null;
        });
      }
    },
  },
};
</script>
<style></style>
