<template>
  <v-sheet width="100%" height="100%" class="d-flex">
    <quran-slider ref="quran_slider" :reciting="reciting" :evaluation="evaluation" @audioended="quranAudioEnded"
      @audiostarted="quranAudioStarted" @update:ayaNumber="updateAyaNumber" @update:sora="updateSora"></quran-slider>
    <v-slide-y-reverse-transition>
      <v-card flat color="transparent" class="position-fixed w-100 bottom-0 pb-2 rounded-t-xl" v-show="!listen">
        <SimpleSpeechCapture ref="speechCapture" :loading="loading" :audioCount="audioCount"
          @audiostarted="speechAudioStarted" @audioended="speechAudioEnded" v-model="audioData"
          @startRecite="reciting = true" @stopRecite="reciting = false">
        </SimpleSpeechCapture>
      </v-card>
    </v-slide-y-reverse-transition>
  </v-sheet>
</template>

<script>
import PronounceEvaluation from "@/components/Pronounce/pronounceEvaluation.vue";
import { EVALUATE } from "@/gql/pronounce/mutations"
import QuranSlider from "@/components/QuranSlider.vue"
import SimpleSpeechCapture from "@/components/common/SimpleSpeechCapture.vue";
import SoraInput from "@/components/quran/SoraInput.vue";
import { mapWritableState } from "pinia";
import { useAppStore } from "@/store/app";
export default {
  name: "PronounceView",
  data() {
    return {
      audioData: null,
      ayaNumber: 0,
      evaluation: null,
      listen: false,
      audioCount: 0,
      loading: false,
      sora: null,
      reciting: false
    };
  },
  components: {
    PronounceEvaluation,
    QuranSlider,
    SoraInput
  },
  mounted() {
    this.pageColor = 'blue-grey-lighten-5'
  },
  beforeRouteLeave() {
    this.pageColor = 'white'
  },
  methods: {
    updateAyaNumber(val) {
      this.ayaNumber = val
      this.reset()
    },
    updateSora(val) {
      this.sora = val
      this.reset()
    },
    speechAudioStarted() {
      this.audioCount++
    },
    speechAudioEnded() {
      this.audioCount--;
    },
    quranAudioStarted() {
      this.audioCount++
      this.listen = true
    },
    meaningAudioStarted() {
      this.audioCount++
    },
    quranAudioEnded() {
      this.audioCount--
      this.listen = false
    },
    meaningAudioEnded() {
      this.audioCount--
    },
    reset() {
      this.audioCount = 0;
      this.evaluation = null
      this.$refs.speechCapture.src = null

    },
    sendToServer(data) {
      this.loading = true
      this.$apollo.mutate({
        mutation: EVALUATE,
        variables: {
          audio: data,
          ayaId: this.$refs.quran_slider.aya.id,
        },
      }).then(({ data }) => {
        this.loading = false
        this.evaluation = data.evaluate
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  watch: {
    audioData(val) {
      if (this.disconnected || val == undefined) return;
      this.sendToServer(val);
    },
  },
  computed: {
    ...mapWritableState(useAppStore, ['pageColor']),
    noAudioRuning() {
      if (this.$refs.audio)
        return this.$refs.audio.paused
      return true
    }
  }
};
</script>
<style>
.aya {
  line-height: 48pt;
  text-align: center;
}
</style>
