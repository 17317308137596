/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import pinia from "./pinia"
import router from "../router";
import vuetify from './vuetify'
import i18n from './i18n'
import { apolloProvider } from "./apollo";

export function registerPlugins (app) {
  app
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(apolloProvider)
}
