<template>
    <v-app>
        <v-container class="fill-height justify-center">
            <div style="width: 100%; height: 50vh" v-html="quranSVG"></div>
        </v-container>
    </v-app>

</template>

<script>
import { Howl } from 'howler';
import isti3atha from '@/assets/audio/isti3atha.mp3'
import quranSVG from '@/assets/imgs/quran.svg?raw'
import anime from 'animejs'
import { mapWritableState } from 'pinia';
import { useAppStore } from '@/store/app';
export default {
    name: "Introduction",
    data() {
        return {
            started: false
        }
    },
    created() {
        this.quranSVG = quranSVG
    },
    mounted() {
        this.showMenu = false
        this.draw()
        var sound = new Howl({
            src: [isti3atha],
            onplayerror: () => {
                console.log('could not play')
            },
            onend: () => this.$router.replace({ name: 'pronounce' }),
        });
        sound.play();
    },
    methods: {
        async draw() {
            await anime({
                targets: 'path',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'linear',
                duration: 1500,
            }).finished;
            anime({
                targets: 'path',
                strokeWidth: [3, 0],
                fill: '#f0b02f',
                duration: 1000,
                easing: 'linear'

            })
            const elem = document.querySelector('svg')
            await anime({
                duration: 1000,
                easing: 'linear',
                update: function (anim) {
                    let value = anim.progress / 10
                    elem.style.filter = `drop-shadow(${value}px ${value}px 2px grey)`
                }

            }).finished

        },
    },
    computed: {
        ...mapWritableState(useAppStore, ['showMenu'])
    }
}
</script>