<template>
  <div>
    <v-overlay class="fill-height d-flex align-center justify-center text-white font-weight-bold text-h4" opacity=".7"
      v-model="micUnapproved">
      <v-card flat color="transparent">
        <v-card-title class="text-center">
          <v-icon size="100" color="orange-lighten-3">
            {{ mdiArrowTopRightThick }}
          </v-icon>
        </v-card-title>
        <v-card-text>
          {{ $t('Please click Allow to use the microphone') }}
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-sheet max-width="600" color="transparent" class="d-flex pa-1 justify-space-around ma-auto">
      <v-btn :icon="mdiSquare" variant="outlined" size="large" v-if="listening" @click="stop" color="red"
        class="justify-center">
      </v-btn>
      <v-btn :loading="loading" variant="outlined" :icon="mdiMicrophone" v-else @click="start" color="red"
        class="justify-center">
      </v-btn>
      <a-v-media v-show="listening" line-color="#2196F3" type="frequ" frequ-direction="mo" :media="stream"
        :frequ-lnum="20" frequ-line-cap :canv-height="30" :canv-width="150"></a-v-media>
      <v-btn variant="outlined" :icon="mdiVolumeHigh" :disabled="src == undefined" color="primary"
        @click="$refs.audio.play()">
      </v-btn>
      <audio ref="audio" @play="$emit('audiostarted')" @ended="$emit('audioended')" class="d-none" :src="src"
        controls></audio>
    </v-sheet>
  </div>
</template>

<script>
import { mdiArrowTopRightThick, mdiMicrophone, mdiPlay, mdiSquare, mdiVolumeHigh } from "@mdi/js";
import { AVMedia } from "vue-audio-visual";
export default {
  name: "SimpleSpeechCapture",
  components: {
    AVMedia,
  },
  props: {
    audioCount: Number,
    loading: Boolean
  },
  data() {
    return {
      stream: null,
      listening: false,
      mediaRecorder: null,
      chunks: [],
      src: null,
      micUnapproved: true
    };
  },
  created() {
    this.mdiMicrophone = mdiMicrophone
    this.mdiSquare = mdiSquare
    this.mdiPlay = mdiPlay
    this.mdiVolumeHigh = mdiVolumeHigh
    this.mdiArrowTopRightThick = mdiArrowTopRightThick

  },
  methods: {
    stop() {
      this.listening = false
      this.mediaRecorder.stop()
      this.$emit('stopRecite')
    },
    start() {
      this.chunks = []
      this.mediaRecorder.start();
      this.listening = true
      this.$emit('startRecite')
    },

  },
  mounted() {
    let supportedType = "audio/webm;codecs=opus"
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            sampleRate: 16000,
            channelCount: 1,
          },
        })
        .then((stream) => {
          this.micUnapproved = false
          this.stream = stream;
          if (!MediaRecorder.isTypeSupported(supportedType)) {
            supportedType = "audio/mp4"
            if (!MediaRecorder.isTypeSupported(supportedType)) {
              return alert(this.$t("Your browser does not support the audio"));
            }
          }
          this.mediaRecorder = new MediaRecorder(stream, {
            type: "audio/webm;codecs=opus",
          });

          this.mediaRecorder.ondataavailable = (event) => {
            this.chunks.push(event.data);
          };

          this.mediaRecorder.onstop = (e) => {
            const blob = new Blob(this.chunks, { type: supportedType });
            if (blob) {
              this.$emit("update:modelValue", blob);
              this.src = URL.createObjectURL(blob);
            }
            this.chunks = []
          }

        });
    }
  }
};
</script>
