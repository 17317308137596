<template>
    <v-dialog max-width="500" v-model="show">
        <v-card theme="dark" color="blue-grey-darken-3" closable class="ma-2">
            <v-card-title class="d-flex justify-space-between">
                <div>{{ $t('meaning') }}</div>
                <v-btn flat @click="show = false" :icon="mdiClose" color="red" variant="plain">
                </v-btn>
            </v-card-title>
            <v-card-text>
                {{ aya.meaning }}
            </v-card-text>
            <v-card-actions class="ma-1 pa-1">
                <v-btn size="x-small" icon @click="$refs.audio.play()">
                    <v-icon>{{ mdiVolumeHigh }}</v-icon>
                </v-btn>
            </v-card-actions>
            <audio @play="$emit('audiostrated')" @ended="$emit('audioended')" class="d-none" ref="audio" controls
                :src="enUrl" autoplay></audio>
        </v-card>
    </v-dialog>
    <v-btn @click="show = true" :icon="mdiTranslateVariant">

    </v-btn>
</template>

<script>
import { mdiPlay, mdiVolumeHigh, mdiVolumeOff, mdiTranslateVariant, mdiClose } from '@mdi/js';

export default {
    name: "MeaningCard",
    props: {
        ayaIndex: Number,
        aya: Object,
        sora: Object,
        sheikhReciting: Boolean
    },
    data() {
        return {
            autoplay: false,
            show: false
        }
    },
    created() {
        this.mdiVolumeHigh = mdiVolumeHigh
        this.mdiVolumeOff = mdiVolumeOff
        this.mdiPlay = mdiPlay
        this.mdiTranslateVariant = mdiTranslateVariant
        this.mdiClose = mdiClose
        this.translation = [
            "In the name of Allāh, the Entirely Merciful, the Especially Merciful",
            "All praise is due to Allāh, Lord of the worlds",
            "The Entirely Merciful, the Especially Merciful",
            "Sovereign of the Day of Recompense",
            "It is You we worship and You we ask for help",
            "Guide us to the straight path",
            "The path of those upon whom You have bestowed favor, not of those who have earned [Your] anger or of those who are astray"
        ]

    },
    computed: {
        enUrl() {

            if (this.sora && this.aya) {
                return `https://be.ilearnquran.org/media/audio/en/${String(this.sora.number).padStart(3, '0')}${String(this.aya.number).padStart(3, '0')}.mp3`
            } else {
                return null
            }

        }

    }
}
</script>