import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
const mode = import.meta.env.MODE;
if (mode === "development") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  from,
  concat,
} from "@apollo/client/core";
import { ApolloLink } from "@apollo/client/link/core";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createApolloProvider } from "@vue/apollo-option";
export const AUTH_TOKEN = "quran-token";
export const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: import.meta.env.VITE_APP_GRAPHQL_HTTP,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers

  const token = localStorage.getItem(AUTH_TOKEN);

  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : "",
    },
  });
  return forward(operation);
});



let activeSocket, timedOut;
const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: () => {
      if (localStorage.getItem(AUTH_TOKEN)) {
        return {
          authToken: localStorage.getItem(AUTH_TOKEN) || "",
        };
      }
      return {};
    },
    url: import.meta.env.VITE_APP_GRAPHQL_WS,
    keepAlive: 5_000,
    on: {
      connected: (socket) => {
        activeSocket = socket;
        console.log("connected");
      },
      closed: (socket) => {
        console.log("disconnected", socket);

      },

      ping: (received) => {
        if (!received)
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, "Request Timeout");
          }, 5_000); // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
    },
  })
);
const uploadLink = createUploadLink()
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  concat(authMiddleware, httpLink)
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});


export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      loadingKey: "loading",
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});

export const refreshToken = () => {
  apolloClient
    .mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        token: localStorage.getItem(AUTH_TOKEN),
      },
    })
    .then(({ data, errors }) => {
      console.log(errors);
    });
};
