<template>
    <v-card :class="`bg-${pageColor}`" :loading="$apollo.queries.aya.loading" flat width="100%"
        class="d-flex flex-column fill-height justify-center">
        <v-slide-y-transition>
            <SoraInput @recite="playSound" :aya="aya" class="position-static ma-auto" style="z-index: 100"
                v-show="!sheikhReciting && !reciting" v-model:sora="sora" v-model:ayaNumber="ayaNumber">
            </SoraInput>
        </v-slide-y-transition>
        <template v-if="aya">
            <v-locale-provider locale="ar" rtl class="d-flex align-center flex-grow-1">
                <v-card-text flat class="d-flex justify-center align-start flex-wrap overflow-auto">
                    <template v-for="(word, index) in words" :id="`word${index}`">
                        <v-card :color="pageColor" class="d-flex flex-column justify-center mb-6"
                            :class="word_class(index)" flat @click="playWord(index)">
                            <v-card-text class="aya ma-0 pa-1" style="font-family: amiri;">
                                {{ word }}
                            </v-card-text>
                            <v-card-text v-if="$i18n.locale != 'ar'" class="pa-1">
                                {{ trans_words[index] }}
                            </v-card-text>
                            <v-scale-transition>
                                <div v-if="evaluation && index >= evaluation.startIndex && index < evaluation.endIndex"
                                    class="pa-0 ma-0">
                                    <v-badge inline
                                        :content="`${(evaluation.ratios[index - evaluation.startIndex] * 100).toFixed(0)}%`"
                                        class="text-caption font-wieght-bold mt-0"
                                        :color="colorCode(evaluation.ratios[index - evaluation.startIndex] * 100)">
                                    </v-badge>
                                </div>
                            </v-scale-transition>
                        </v-card>
                        &nbsp;
                    </template>
                </v-card-text>
            </v-locale-provider>
            <v-card-actions class="justify-space-around">
                <v-btn v-if="sheikhReciting" :icon="mdiPause" size="xsmall" color="primary" @click="pauseSound">
                </v-btn>
            </v-card-actions>
            <audio @timeupdate="updateAudioTime" @ended="audioended" @play="audioStarted" @pause="audioended"
                ref="audio" controls :src="ayaUrl" autoplay class="d-none" @paused="$emit('paused')"></audio>
        </template>
    </v-card>
</template>

<script>
import { useAppStore } from "@/store/app"
import { mdiPlay, mdiVolumeHigh, mdiPause } from '@mdi/js';
import { AYA } from "@/gql/quran/queries"
import { mapState } from "pinia";
export default {
    name: "QuranSlider",
    props: {
        evaluation: Object,
        reciting: Boolean
    },
    data() {
        return {
            currentWord: null,
            currentWordsRange: [],
            ayaNumber: 1,
            audioTime: 0,
            sora: null,
            sheikhReciting: false,
        }
    },
    created() {
        this.mdiPlay = mdiPlay
        this.mdiVolumeHigh = mdiVolumeHigh
        this.mdiPause = mdiPause
    },
    apollo: {
        aya: {
            query: AYA,
            variables() {
                return {
                    number: this.ayaNumber,
                    soraNumber: this.sora.number
                }
            },
            skip() {
                return this.sora == undefined
            }
        }
    },
    methods: {
        letter_color(word_index, letter_index) {
            if (this.evaluation && word_index >= this.evaluation.startIndex && word_index < this.evaluation.endIndex && this.evaluation.misPos[word_index - this.evaluation.startIndex].includes(letter_index)) {
                return 'text-error'
            }

        },
        word_class(index) {
            let wclass = ''
            if (this.evaluation && (index < this.evaluation.index || index >= this.evaluation.index)) {
                wclass = 'text-grey'
            }
            if (this.aya && this.aya.segments && this.aya.segments[index][0] <= this.audioTime && this.aya.segments[index][1] >= this.audioTime) {
                wclass = 'text-red'
                const element = document.getElementById(`word${index}`);

            }
            return wclass
        },
        colorCode(percent) {
            if (percent == 100) {
                return "success"
            }
            if (percent > 90) {
                return "orange-lighten-3"
            }
            if (percent > 70) {
                return "orange-darken-1"
            }
            else {
                return "error"
            }
        },
        audioended() {
            this.currentWord = -1
            this.$emit('audioended')
            this.sheikhReciting = false
        },
        playWord(index) {
            const start = this.aya.segments[index][0]
            this.$refs.audio.currentTime = start / 1000
            this.$refs.audio.play()
        },
        updateAudioTime(e) {
            this.audioTime = this.$refs.audio.currentTime * 1000
        },
        playSound() {
            this.$refs.audio.play()
            this.$emit('audiostarted')
        },
        pauseSound() {
            this.$refs.audio.pause()
            this.audioended()
            this.sheikhReciting = false
        },
        audioStarted() {
            this.sheikhReciting = true
            this.$emit('audiostarted')
        }
    },
    watch: {
        ayaNumber(val) {
            this.$emit('update:ayaNumber', val)
        },
        sora(val) {
            this.$emit('update:sora', val)
        },
    },
    computed: {
        ...mapState(useAppStore, ['pageColor']),
        words() {
            if (!this.aya) {
                return []
            }
            return this.aya.text.split(' ')
        },
        trans_words() {
            if (!this.aya) {
                return []
            }
            return this.aya.transliteration.split(' ')
        },
        ayaUrl() {
            if (this.sora && this.aya) {
                return `https://be.ilearnquran.org/media/audio/quran/Husary_64kbps_${String(this.sora.number).padStart(3, '0')}${String(this.aya.number).padStart(3, '0')}.mp3`
            } else {
                return null
            }
        },
    },
}
</script>
<style>
.v-card .v-card-text.aya {
    font-size: min(4.2vw, 26pt);
    line-height: min(8vw, 80px);

}
</style>