<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-text>
                {{ $t('You are using an old version. Upgrade?') }}
            </v-card-text>
            <v-card-actions>
                <v-btn @click="upgrade">
                    {{ $t('yes') }}
                </v-btn>
                <v-btn @click="showDialog = false">
                    {{ $t('no') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div class="position-absolute text-grey" style="bottom: 0px; left: 1vw" size="xs">
        <v-btn class="text-caption" @click="showDialog = true" size="xs" v-if="requireUpgrade" variant="text"
            color="error">
            <v-icon>{{ mdiAlertCircleOutline }}</v-icon>
            {{ $t('version') }} {{ version }}
        </v-btn>
        <span style="font-size: .55rem;" v-else>{{ $t('version') }} {{ version }} </span>
    </div>
</template>

<script>
import { ILQ_VERSION } from "@/gql/common/queries";
import { mdiAlertCircleOutline } from "@mdi/js";

export default {
    name: "ILQVersion",
    data() {
        return {
            requireUpgrade: false,
            showDialog: false
        }
    },
    created() {
        this.version = import.meta.env.VITE_APP_VERSION
        this.mdiAlertCircleOutline = mdiAlertCircleOutline
    },
    methods: {
        upgrade() {
            window.location.reload(true);
        }
    },
    apollo: {
        version: {
            query: ILQ_VERSION,
            result({ data }) {
                this.requireUpgrade = data.version != import.meta.env.VITE_APP_VERSION
                this.showDialog = this.requireUpgrade
            }
        }
    },
}
</script>