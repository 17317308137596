<template>
    <v-bottom-navigation density="comfortable" height="80" class="position-fixed">
        <v-btn v-for="(btn, index) in buttons" class="text-uppercase" :to="btn.to">
            <v-icon>{{ btn.icon }}</v-icon>
            <span>{{ $t(btn.caption) }}</span>
        </v-btn>
    </v-bottom-navigation>
</template>
<script>
import { mdiAccountCashOutline, mdiHomeOutline, mdiInformationOutline } from '@mdi/js';

export default {
    name: "BottomNavigation",
    created() {
        this.buttons = [
            {
                caption: 'home',
                to: { name: 'selectlang' },
                icon: mdiHomeOutline
            },
            {
                caption: 'subscription',
                to: { name: 'subscription' },
                icon: mdiAccountCashOutline
            },
            {
                caption: 'about us',
                to: { name: "AboutUs" },
                icon: mdiInformationOutline
            }
        ]
    }
}
</script>