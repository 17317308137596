<template>
  <v-snackbar rounded v-model="show" :timeout="timeout" :color="color" centered>
    <!-- {{message}} -->
    <p id="notify-text" v-html="message"></p>
    <template v-slot:actions>
      <v-btn size="small" icon @click="show = false" dark v-bind="attrs">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "NotifyMessage",
  data: () => ({
    timeout: 6000,
    show: false,
    message: null,
    color: "success",
  }),
  created() {
    this.mdiClose = mdiClose;
  },
  methods: {
    open(message, color = "success", timeout = 6000) {
      this.timeout = timeout;
      this.message = message;
      this.color = color;
      this.show = true;
    },
  },
};
</script>
