<template>
  <v-card flat class="ma-2 text-h6">
    <v-locale-provider locale="ar">
      <div class="d-flex justify-space-evenly flex-wrap amiri-regular text-h6 mt-6">
        <template v-for="(word, index) in   evaluation.text.split(' ')  ">
          <div class="d-flex flex-column justify-center align-center mb-6">
            <div :class="'text-' + colorCode(evaluation.ratios[index] * 100)">
              {{ word }}
            </div>
            <v-slide-x-transition>
              <v-badge inline :content="`${evaluation.ratios[index] * 100}%`" class="text-caption font-wieght-bold mt-2"
                :color="colorCode(evaluation.ratios[index] * 100)"> </v-badge>
            </v-slide-x-transition>
          </div>
        </template>
      </div>
    </v-locale-provider>
  </v-card>
</template>

<script>
import anime from "animejs"
import { mdiCheck, mdiClose, mdiCheckBold, mdiCloseThick } from "@mdi/js";
export default {
  name: "PronounceEvaluation",
  props: ["refText", "evaluation"],

  created() {
    this.mdiCheck = mdiCheck;
    this.mdiClose = mdiClose;
    this.mdiCheckBold = mdiCheckBold;
    this.mdiCloseThick = mdiCloseThick


  },

  methods: {
    colorCode(percent) {
      if (percent == 100) {
        return "success"
      }
      if (percent > 90) {
        return "orange-lighten-3"
      }
      if (percent > 70) {
        return "orange-darken-1"
      }
      else {
        return "error"
      }
    }
  },
};
</script>