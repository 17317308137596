<template>
    <v-sheet class="d-flex fill-height justify-space-around flex-column">
        <v-img max-height="30vh" src="@/assets/logo.svg"></v-img>
        <v-card-text class="flex-grow-0">
            <h1>
                ملقن القرآن الكريم ومصحح التلاوة بالذكاء الاصطناعي
            </h1>
            <h1>
                AI Powered Quran Teacher and Recitation Corrector
            </h1>
        </v-card-text>
        <v-card-actions width="100%" flat class="justify-space-around align-center">
            <v-btn class="border-0" :class="`border-${locale.color}`" variant="outlined" rounded elevation="2"
                theme="dark" :color="locale.color" @click="selectLocale(locale.value)" max-height="150" max-width="150"
                height="30dvw" width="30dvw" flat v-for="(locale, index) in locales">
                {{ locale.title }}
            </v-btn>
        </v-card-actions>
    </v-sheet>
</template>
<script>
import arbtn from '@/assets/imgs/arbtn.svg'
import enbtn from '@/assets/imgs/enbtn.svg'
import { AUTH_TOKEN } from '@/plugins/apollo'
export default {
    name: "SelectLanguage",
    created() {
        this.locales = [
            {
                value: 'en',
                title: 'English',
                src: enbtn,
                color: 'blue-darken-3'
            },
            {
                value: 'ar',
                title: 'عربي',
                src: arbtn,
                color: 'teal-darken-1'
            }
        ]
    },
    methods: {
        selectLocale(val) {
            this.$i18n.locale = val
            localStorage.setItem('locale', val)
            if (localStorage.getItem(AUTH_TOKEN) == undefined || localStorage.getItem(AUTH_TOKEN) == "undefined") {
                this.$router.push({ name: 'terms' })
            } else {
                this.$router.push({ name: 'introduction' })
            }
        }
    }
}
</script>

<style>
#app h1 {
    font-size: min(5.2dvw, 25pt);
}
</style>