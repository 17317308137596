import gql from "graphql-tag";

export const SORAT = gql`
query Sorat {
  sorat {
    ayatCount
    id
    ar
    en
    number
  }
} 
`

export const AYA = gql`
query Aya($number: Int = 1, $soraNumber: Int = 1) {
    aya(number: $number, soraNumber: $soraNumber) {
      id
      number
      segments
      text
      transliteration
      meaning
    }
  }
`