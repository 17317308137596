<template>
  <v-app>
    <Notify ref="notify" />
    <pwa-install-button />
    <v-layout>
      <v-main :class="`bg-${pageColor}`">
        <RouterView />
      </v-main>
      <bottom-navigation :active="showMenu"></bottom-navigation>
      <version></version>
    </v-layout>
  </v-app>
</template>


<script>
import PwaInstallButton from "@/components/common/PwaInstallButton.vue";
import Notify from "@/components/common/notify.vue";
import { RouterView } from "vue-router";
import BottomNavigation from "@/components/common/BottomNavigation.vue"
import { mapState } from "pinia";
import { useAppStore } from "./store/app";
import Version from "@/components/common/Version.vue"
export default {
  components: {
    RouterView,
    Notify,
    PwaInstallButton,
    BottomNavigation,
    Version
  },
  created() {
    if (localStorage.getItem('locale')) {
      this.$i18n.locale = localStorage.getItem('locale')
    }

  },

  computed: {
    ...mapState(useAppStore, ['showMenu', 'pageColor'])
  }
};
</script>

<style>
html {
  font-family: "El Messiri";
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .colored {
    background-color: v-bind('pageColor');
  }

  * {
    font-family: "El Messiri";
  }

  .amiri-regular {
    font-family: "Amiri", serif;
    font-weight: 400;
    font-style: normal;
  }
}
</style>
