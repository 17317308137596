import { createI18n } from "vue-i18n";
import myar from "../locales/ar.json";
import myen from "../locales/en.json"
import { en as vuetifyen, ar as vuetifyar } from 'vuetify/locale'
export default createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en:{
      $vuetify:{
        ...vuetifyen
      },
      ...myen,
    }, 
    ar:{
      $vuetify:{
        ...vuetifyar
      },
      ...myar,
    }
  }

});
