// Utilities
import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    showMenu: true,
    pageColor: 'white',
  }),
  actions: {
  },
});
